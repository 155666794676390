// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// @import "~ngx-sharebuttons/themes/default";
@import "/node_modules/ngx-sharebuttons/themes/default/default-theme.scss";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$DMK-Youth-Wing-Website-primary: mat-palette($mat-indigo);
$DMK-Youth-Wing-Website-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$DMK-Youth-Wing-Website-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$DMK-Youth-Wing-Website-theme: mat-light-theme(
  (
    color: (
      primary: $DMK-Youth-Wing-Website-primary,
      accent: $DMK-Youth-Wing-Website-accent,
      warn: $DMK-Youth-Wing-Website-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($DMK-Youth-Wing-Website-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/scss/default.theme.scss";

/* Importing Styles for Ngx Toastr*/
@import "~ngx-toastr/toastr";

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Mukta Malar", Roboto, sans-serif;
  background-color: #f7f7f7;
  // scrollbar-width: thin;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

// ::-webkit-scrollbar {
//   display: none;
// }

.center {
  padding: 2rem 4rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 6.75rem;
}

.bg-image {
  /* The image used */
  background-image: url("./assets/images/form-banner.png");
  /* Full height */
  min-height: 110vh;
  margin-top: -6.75rem;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.submit {
  text-align: center;
  &-btn {
    color: white;
    font-weight: 800;
    background-color: red;
    padding: 0.7rem 7rem;
    font-size: 1.3rem;
  }
}

.site-form {
  background-color: white;
  height: 100%;
  padding: 1rem;
}

input {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}

#contatti {
  background-color: #70c3be;
  letter-spacing: 2px;
}

#contatti a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 575.98px) {
  #contatti {
    padding-bottom: 800px;
  }
  #contatti .maps iframe {
    width: 100%;
    height: 450px;
  }
}

@media (min-width: 576px) {
  #contatti {
    padding-bottom: 800px;
  }

  #contatti .maps iframe {
    width: 100%;
    height: 450px;
  }
}

@media (min-width: 768px) {
  #contatti {
    padding-bottom: 350px;
  }

  #contatti .maps iframe {
    width: 100%;
    height: 850px;
  }
}

@media (min-width: 992px) {
  #contatti {
    padding-bottom: 200px;
  }

  #contatti .maps iframe {
    width: 100%;
    height: 700px;
  }
}

#author a {
  color: #fff;
  text-decoration: none;
}

.heading {
  &-gallery {
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
    padding: 2rem 2rem 1rem 2rem;
  }
}

// .scrollable::-webkit-scrollbar {
//   // Width of vertical scroll bar
//   width: 10px;
//   // Height of horizontal scroll bar
//   height: 10px;
// }

// .scrollable::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
//   background-color: black;
// }

// .scrollable::-webkit-scrollbar-thumb {
//   border-radius: 8px;
//   background: #505050;
// }

.slick-wrapper {
  width: 90%;
  margin: auto;
}

.slick-prev,
.slick-next {
  top: 45% !important;
}

.slick-prev {
  z-index: 9999 !important;
  left: -30px !important;
}

.slick-next {
  z-index: 9999 !important;
  right: -5px !important;
}

.slick-prev:before,
.slick-next:before {
  color: red !important;
  font-size: 3rem !important;
}

.slide {
  margin: 1rem !important;
}

.form-control {
  font-weight: 500;
}

.custom .ng-placeholder {
  font-weight: 500;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-weight: 600;
  font-size: 1.1rem;
}

.description {
  padding: 0;
  &-heading {
    padding: 0;
    color: rgb(255, 196, 0);
    font-size: 3.4rem;
    font-weight: bolder;
  }
  &-text {
    color: white;
    font-size: 1.3rem;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1.2rem;
    margin-top: 1rem;
  }
}

// remove x button
.ng-select .ng-clear-wrapper {
  display: none;
}

// ckEditor is not support blockquote (its apply external css), so here i am giving css for blockquote which coming from ckEditor(backend)
blockquote {
  font-style: italic;
  font-family: Georgia, Times, "Times New Roman", serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
blockquote p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

@media (max-width: 992px) {
  .site-form {
    width: 100%;
  }
  .description {
    &-heading {
      font-size: 2.8rem;
    }
    &-text {
      font-size: 1.2rem;
    }
  }
}

@media (max-width: 768px) {
  .description {
    &-heading {
      font-size: 2.4rem;
    }
    &-text {
      font-size: 1rem;
    }
  }
}

@media (max-width: 576px) {
  .bg-image .row {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
  }
  .description {
    &-heading {
      font-size: 1.8rem;
    }
    &-text {
      font-size: 0.9rem;
    }
  }
}

.slick-dots li button:before {
  color: red !important;
  font-size: 15px !important;
}
.slick-dots {
  position: relative !important;
  bottom: 0 !important;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  /* width: 100%; */
}

// .slick-slide {
//   margin: 0 20px !important;
// }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// body {
//   min-height: 100vh;
//   position: relative;
//   margin: 0;
//   padding-bottom: 100px; //height of the footer
//   box-sizing: border-box;
// }
